import * as React from 'react'
import get from 'lodash/get'
import fetch from 'isomorphic-unfetch'
import Router from 'next/router'
import classnames from 'classnames'

import getUrl from 'lib/url'
import { sendTeaCommEvent } from 'lib/tea-analyze'

interface IResult {
  keyword: string
}
interface IListProps {
  data: IResult[]
  curIndex: number
}
interface IState {
  data: IResult[]
  show: boolean
}

const handler = (keyword: string, searchMode = 'common') => {
  sendTeaCommEvent('click_top_search', {
    search_content: keyword,
    search_mode: searchMode,
  })
  sessionStorage.setItem('search_content', keyword)
  sessionStorage.setItem('search_mode', searchMode)
  Router.push(`/search?keyword=${keyword}`, `/search?keyword=${keyword}`)
}

function ResultList({ data, curIndex }: IListProps) {
  return (
    <ul className="result">
      {data.map((item: IResult, index: number) => (
        <li
          key={item.keyword}
          className={classnames({
            active: index === curIndex,
          })}
          onClick={(e: React.MouseEvent<HTMLLIElement, MouseEvent>) => {
            e.stopPropagation()
            handler(item.keyword)
            // setValue(item.keyword)
          }}
        >
          {item.keyword}
        </li>
      ))}
      <style jsx>
        {`
          .result {
            position: absolute;
            top: 36px;
            left: -2px;
            z-index: 1000;
            width: 320px;
            color: #333333;
            background: #fff;
            margin-top: 4px;
            box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.2);
          }
          .result li {
            line-height: 32px;
            margin: 0 10px;
            font-size: 14px;
            border-bottom: 1px dotted #e6e6e6;
            cursor: pointer;
          }
          .result li:last-child {
            border-bottom: 0;
          }
          ul.result .active {
            color: #ff9100;
          }
        `}
      </style>
    </ul>
  )
}

export default function Search() {
  const [focus, setFocus] = React.useState(false)
  const [value, setValue] = React.useState('')
  /* 列表当前选中项索引 */
  const [curIndex, setCurIndex] = React.useState(0)
  /* 列表数据 */
  const [list, setList] = React.useState<IState>({ show: false, data: [] })

  const searchDivRef = React.useRef<HTMLDivElement>(null)
  const buttonRef = React.useRef<HTMLButtonElement>(null)

  React.useEffect(() => {
    const searchDivDom = searchDivRef.current
    const searchButtonDom = buttonRef.current
    const onMouseEnter = () => {
      if (searchDivDom) searchDivDom.classList.add('button-hover')
    }
    const onMouseLeave = () => {
      if (searchDivDom) searchDivDom.classList.remove('button-hover')
    }
    if (searchDivDom && searchButtonDom) {
      searchButtonDom.addEventListener('mouseenter', onMouseEnter)
      searchButtonDom.addEventListener('mouseleave', onMouseLeave)
    }

    return () => {
      if (searchDivDom && searchButtonDom) {
        searchButtonDom.removeEventListener('mouseenter', onMouseEnter)
        searchButtonDom.removeEventListener('mouseleave', onMouseLeave)
      }
    }
  }, [searchDivRef.current, buttonRef.current])

  React.useEffect(() => {
    if (!value) {
      return
    }
    fetch(getUrl(`/motor/searchacl/complete?keyword=${value}`))
      .then(res => res.json())
      .then(json => {
        setList({ show: true, data: json.data })
      })
  }, [value])

  return (
    <div className="fl clearfix search" ref={searchDivRef}>
      <span className="search-icon fl" />
      <input
        type="search"
        className="fl search-form"
        placeholder="请输入你要搜索的内容"
        onChange={e => {
          setCurIndex(0)
          setValue(e.target.value)
        }}
        onBlur={() => {
          setFocus(false)
          setTimeout(() => {
            setList({ ...list, show: false })
          }, 200)
        }}
        onFocus={() => {
          setList({ ...list, show: true })
          setFocus(true)
        }}
        onKeyDownCapture={e => {
          if (e.keyCode === 40) {
            const index = curIndex + 1 === list.data.length ? 0 : curIndex + 1
            setCurIndex(index)
            // setValue(list.data[index].keyword)
          }
          if (e.keyCode === 38) {
            const index =
              curIndex - 1 === -1 ? list.data.length - 1 : curIndex - 1
            setCurIndex(index)
            // setValue(list.data[index].keyword)
          }
          if (e.keyCode === 13) {
            console.log(list.data, curIndex)
            handler(get(list, `data[${curIndex}].keyword`) || value, 'sug')
          }
        }}
      />
      <button
        ref={buttonRef}
        type="submit"
        className="fl search-btn"
        onClick={e => {
          e.stopPropagation()
          handler(value)
        }}
      >
        搜索
      </button>
      {list.show && <ResultList data={list.data} curIndex={curIndex} />}
      <style jsx>
        {`
          .search {
            margin-left: 40px;
            border-radius: 2px;
            position: relative;
            background-color: #ffffff;
            width: 400px;
            margin-top: 0;
            border: solid 2px #ffcc32;
          }
          .search.button-hover {
            border: solid 2px #e5b72c;
          }
          .search-icon {
            display: inline-block;
            font-size: 16px;
            width: 16px;
            height: 16px;
            margin: 10px 0 10px 12px;
            color: #cccccc;
            background-image: url(${require('../public/static/search-ic@3x.png')});
            background-position: center;
            background-size: 100%;
          }
          .search-form {
            width: 284px;
            height: 36px;
            line-height: 12px;
            padding: 0 10px 0 10px;
            outline: 0;
            border: 0;
            font-size: 12px;
            box-sizing: border-box;
            -webkit-appearance: none;
          }
          .search-btn {
            line-height: 36px;
            font-size: 14px;
            border: 0;
            background-color: #ffcc32;
            cursor: pointer;
            outline: none;
            float: right;
            width: 80px;
            border-radius: 0;
          }
          .search-btn:hover {
            background-color: #e5b72c;
          }
          .search-btn:active {
            background-color: #e5b72c;
          }
        `}
      </style>
    </div>
  )
}
