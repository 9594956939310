import getQuery from '@arsenal/arsenal/modules/getQuery'

/**
 * sessionStorage工具
 */
const _sessionStorage = (() => {
  return {
    getItem: (key: string): string | null => {
      try {
        return sessionStorage.getItem(key)
      } catch (e) {
        return null
      }
    },
    setItem: (key: string, value: string): void => {
      try {
        sessionStorage.setItem(key, value)
      } catch (e) {}
    },
  }
})()

/**
 * 获取zt参数，用于统计pc站来源及线索提交场景使用
 * 获取方式：链接中zt参数 > session storage中zt参数 > 自然流量 default_pc
 * FIXME: 上线后观察是否存在无限制向后追加zt问题，并解决
 * @param {string} appendLabel - 用于统计页内不同位置带入留咨
 */
export const getZT = (appendLabel = ''): string => {
  try {
    const paramZT = getQuery('zt', window.location.search.slice(1) || '')

    const zt =
      typeof paramZT === 'string'
        ? paramZT
        : _sessionStorage.getItem('__zt') || 'default_pc'

    if (zt !== 'default_pc') {
      _sessionStorage.setItem('__zt', zt)
    }

    return `${zt}${appendLabel ? `_${appendLabel}` : ''}`
  } catch (e) {
    return `default_pc${appendLabel ? `_${appendLabel}` : ''}`
  }
}
