import React, { createContext } from 'react'
import cookie from 'cookie'
import App, { Container } from 'next/app'
import Head from 'next/head'
import Router from 'next/router'
import NProgress from 'nprogress'
import fetch from 'isomorphic-unfetch'

import Header2 from 'Components/header2'
import RightSideNavigation from 'Components/right-side-navigation'
import { GlobalStoreProvider } from 'Components/store'
import { CacheStoreProvider } from 'Components/cache-store'
import 'lib/font.less'
import getPageEnterId from 'lib/page-enter'
import getUrl from 'lib/url'
import { getZT } from 'lib/zt-util'
import { unicodeToChar } from 'lib/utils'
import teaInit, {
  sendTeaEnterEvent,
  sendTeaStayEvent,
  sendTeaCommEvent,
  teaConfig,
} from 'lib/tea-analyze'
import safeGet from '@arsenal/arsenal/modules/safeGet'

const acrawler = require('@byted/acrawler')
export default class MyApp extends App {
  state = {
    isVisible: true,
  }

  UNSAFE_componentWillMount() {
    // client side need fetch acrawler captcha token
    if (
      typeof window !== 'undefined' &&
      process.env.NODE_ENV !== 'development'
    ) {
      this.fetchAcrawlerCaptchaToken()
    }
  }

  componentDidMount() {
    teaInit({
      appId: 1839,
      zt: getZT(),
    })
    const cookies = cookie.parse(document.cookie)
    let userData
    try {
      userData = JSON.parse(cookies.user_data)
    } catch (error) {
      // console.error(error)
    }
    if (userData) {
      teaConfig({
        user_id: userData.user_id,
      })
    }
    const routerPath = Router!.router!.asPath || ''
    const params = getPageEnterId(routerPath)
    sendTeaEnterEvent(params)
    Router.events.on('routeChangeStart', url => {
      requestAnimationFrame(() => {
        NProgress.start()
        NProgress.set(0.5)
      })
      sendTeaStayEvent()
    })
    Router.events.on('routeChangeComplete', (url: string) => {
      const params = getPageEnterId(url)
      // console.log('routeChangeComplete', url)
      requestAnimationFrame(() => {
        NProgress.done()
      })
      sendTeaEnterEvent(params)
    })
    Router.events.on('routeChangeError', () => {
      requestAnimationFrame(() => {
        NProgress.done()
      })
    })
    Router.beforePopState(({ as }) => {
      // ie9不支持history
      if (!window.history || !window.history.pushState) {
        window.open(as)
        // window.location.href = as
        return false
      }
      return true
    })
    document.addEventListener('visibilitychange', this.handleVisibilityChange)
  }

  componentWillUnmount() {
    document.removeEventListener(
      'visibilitychange',
      this.handleVisibilityChange
    )
  }

  handleVisibilityChange = () => {
    this.setState({ isVisible: !document.hidden })
  }

  fetchAcrawlerCaptchaToken = () => {
    fetch(getUrl('/acrawler/get_captcha_token'))
      .then((res: any) => res.json())
      .then(data => {
        const token = safeGet(data, 'token') || ''
        const result = token.match(/tac='(.*)'/)

        if (result) {
          window['tac'] = unicodeToChar(result[1])
        }

        this.fetchAcrawlerCaptchaCheck()
      })
      .catch(e => {
        console.error('token', e)
      })
  }

  fetchAcrawlerCaptchaCheck = () => {
    const nonce = {
      url: encodeURI(`${window.location.origin}/acrawler/check_captcha`),
    }

    let sig = ''
    try {
      sig = acrawler.sign(nonce)
    } catch (e) {
      console.error('token', e)
    }

    fetch(getUrl(`/acrawler/check_captcha?_signature=${sig}`))
      .then(res => res.json())
      .catch(e => {
        console.error(e)
      })
  }

  render() {
    const { Component, pageProps } = this.props
    const { isVisible } = this.state
    return (
      <Container>
        <Head>
          <title key="title">懂车帝 - 说真的还得懂车帝</title>
          <meta
            key="keywords"
            name="keywords"
            content="懂车帝官网,汽车,汽车网站,汽车网,汽车报价,汽车报价大全,汽车图片,新闻,评测,视频,车型库,车型,车型大全,参数,配置,报价,汽车之家,车友,车友圈,社区,俱乐部"
          />
          <meta
            key="description"
            name="description"
            content="懂车帝是一个汽车资讯平台，懂车更懂你。它会聪明地分析你的兴趣爱好，自动为你推荐喜欢的内容，提供最新汽车报价，汽车图片，汽车价格大全，汽车新闻、行情、评测、导购内容，看车选车买车就上懂车帝。"
          />
          <meta
            name="viewport"
            content="width=device-width,initial-scale=1,maximum-scale=5,minimum-scale=1,viewport-fit=cover"
          />
        </Head>
        <CacheStoreProvider>
          <GlobalStoreProvider value={{ isVisible }}>
            <Header2 />
            <main>
              <Component {...pageProps} />
            </main>
          </GlobalStoreProvider>
        </CacheStoreProvider>
        <RightSideNavigation />
        <style jsx global>
          {`
            /* Make clicks pass-through */
            #nprogress {
              pointer-events: none;
            }
            body {
              overflow-x: auto;
              overflow-y: scroll;
            }
            #nprogress .bar {
              position: fixed;
              z-index: 1031;
              top: 0;
              left: 0;
              width: 100%;
              height: 2px;
              background: #ffe100;
            }

            /* Fancy blur effect */
            #nprogress .peg {
              display: block;
              position: absolute;
              right: 0px;
              width: 100px;
              height: 100%;
              box-shadow: 0 0 10px #ffe100, 0 0 5px #ffe100;
              opacity: 1;

              -webkit-transform: rotate(3deg) translate(0px, -4px);
              -ms-transform: rotate(3deg) translate(0px, -4px);
              transform: rotate(3deg) translate(0px, -4px);
            }
          `}
        </style>
      </Container>
    )
  }
}
