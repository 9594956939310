import * as React from 'react'
import { on, off } from '@arsenal/arsenal/modules/event'

import { sendTeaCommEvent } from 'lib/tea-analyze'
import BackToTop from './back-to-top'
import { useRouter } from 'next/router'

const menu_top_img = require('../public/static/menu-top@3x.png')
const menu_bottom_img = require('../public/static/menu-bottom@3x.png')

export default function RightSideNavigation() {
  const router = useRouter()
  if (router.pathname !== '/home') {
    return <BackToTop />
  }
  const [show, updateShow] = React.useState(false)
  const [activeNow, setActiveNow] = React.useState('')

  const withinView = React.useCallback((domID: string) => {
    const dom = document.getElementById(domID)
    if (!dom) {
      return false
    }
    return dom.getBoundingClientRect().top + dom.clientHeight > 0
  }, [])
  const handleScroll = React.useCallback(() => {
    const scrollElement = document.documentElement
    const start_dom = document.getElementById('original_column')
    if (
      start_dom &&
      scrollElement.scrollTop >
        start_dom.getBoundingClientRect().top + window.pageYOffset
    ) {
      updateShow(true)
    } else {
      updateShow(false)
    }

    const domIDs = [
      // 'hot_news',
      'original_column',
      'dong_che_fen',
      'friend_circle',
      'featured_videos',
      'featured_images',
      'local_market',
      'home_feed',
    ]
    const elements = domIDs.map(e => {
      return {
        id: e,
        withinView: withinView(e),
      }
    })

    for (const element of elements) {
      if (element.withinView) {
        setActiveNow(element.id)
        break
      }
    }
  }, [])
  React.useEffect(() => {
    handleScroll()
    on(document, 'scroll', handleScroll)
    return () => {
      off(document, 'scroll', handleScroll)
    }
  }, [])

  const handleItemClick = React.useCallback((e: React.SyntheticEvent) => {
    const target = e.target as HTMLElement
    const type = target.dataset.type || ''

    const dom = document.getElementById(type) || document.body
    if (dom) {
      const to = dom?.offsetTop
      window.scrollTo({
        top: to,
        behavior: 'smooth',
      })
    }
  }, [])

  if (!show) {
    return null
  }
  return (
    <div className="navigation-menu">
      <img className="menu-top-img" src={menu_top_img} />
      <div className="item-wrapper" onClick={handleItemClick}>
        {/* <span
          className={`item ${activeNow === 'hot_news' ? 'active' : ''}`}
          data-type="hot_news"
        >
          直播
        </span> */}
        <span
          className={`item ${activeNow === 'original_column' ? 'active' : ''}`}
          data-type="original_column"
        >
          原创
        </span>
        <span
          className={`item ${activeNow === 'dong_che_fen' ? 'active' : ''}`}
          data-type="dong_che_fen"
        >
          懂车分
        </span>
        {/* <span
          className={`item ${activeNow === 'friend_circle' ? 'active' : ''}`}
          data-type="friend_circle"
        >
          车友圈
        </span> */}
        <span
          className={`item ${activeNow === 'featured_videos' ? 'active' : ''}`}
          data-type="featured_videos"
        >
          视频
        </span>
        <span
          className={`item ${activeNow === 'featured_images' ? 'active' : ''}`}
          data-type="featured_images"
        >
          图片
        </span>
        {/* <span
          className={`item ${activeNow === 'local_market' ? 'active' : ''}`}
          data-type="local_market"
        >
          本地车市
        </span> */}
        <span
          className={`item ${activeNow === 'home_feed' ? 'active' : ''}`}
          data-type="home_feed"
        >
          资讯
        </span>
        <span className="back-to-top">返回顶部</span>
      </div>
      <style jsx>
        {`
        @media screen and (max-width: 1439px){
          .navigation-menu{
            display: none;
          }
        }
        @media screen and (min-width: 1440px) {
          .back-to-top{
            color: #c9cbd6;
            width: 100%;
            height: 48px;
            line-height: 48px;
            white-space: nowrap;
            background: url("${menu_bottom_img}") no-repeat center center / 100% 100%;
            cursor: pointer;
          }
          .back-to-top:hover{
            color: #FFCD32;
          }
          .item{
            width: 100%;
            height: 48px;
            line-height: 48px;
            white-space: nowrap;
            cursor: pointer;
          }
          .item:hover{
            background-color: rgba(255, 255, 255, 0.24);
          }
          .active{
            background-color: #ffcd32;
            color: #1f2129;
          }
          .active:hover{
            background-color: #ffcd32;
          }
          .item-wrapper{
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: stretch;
          }
          .menu-top-img {
            width: 100%;
            height: 28px;
            object-fit: fill;
            object-position: center;
            background: white;
          }
          .navigation-menu {
            position: fixed;
            left: 50%;
            top: 40px;
            margin-left: calc(1196px / 2 + 16px);
            width: 88px;
            height: auto;
            font-size: 16px;
            text-align: center;
            border-radius: 2px;
            -webkit-backdrop-filter: blur(5.8px);
            backdrop-filter: blur(5.8px);
            background-color: #1f2129;
            color: #ffffff;
        }
          
        `}
      </style>
    </div>
  )
}
