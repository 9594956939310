import * as React from 'react'
import { on, off } from '@arsenal/arsenal/modules/event'

import { sendTeaCommEvent } from 'lib/tea-analyze'

export default function BackToTop() {
  const [show, updateShow] = React.useState(false)
  React.useEffect(() => {
    function handleScroll() {
      const scrollElement =
        document.scrollingElement || document.documentElement
      if (scrollElement.scrollTop > 100) {
        updateShow(true)
      } else {
        updateShow(false)
      }
    }
    on(document, 'scroll', handleScroll)
    return () => {
      off(document, 'scroll', handleScroll)
    }
  }, [])
  if (!show) {
    return null
  }
  const handleButtonClick = () => {
    const scrollElement = document.scrollingElement || document.documentElement
    const top = scrollElement.scrollTop
    sendTeaCommEvent('return_to_top')
    scrollSmoothly(scrollElement, -top)()
  }
  return (
    <div onClick={handleButtonClick} className="back-top-button">
      <div className="back-to-top">
        <span className="icon-invalid-name icon-up" />
      </div>
      <p>返回顶部</p>
      <style jsx>
        {`
          .back-top-button {
            position: fixed;
            left: 50%;
            bottom: 120px;
            margin-left: 617px;
            width: 80px;
            height: 77px;
            border: 0;
            outline: 0;
            font-size: 12px;
            text-align: center;
            border: solid 1px #e6e6e6;
            background-color: #ffffff;
            cursor: pointer;
            z-index: 100;
          }
          @media screen and (max-width: 1400px) {
            .back-top-button {
              left: auto;
              margin-left: 0;
              right: 10px;
            }
          }
          .back-to-top {
            margin: 0 auto;
            width: 24px;
            height: 24px;
            margin-top: 16px;
            margin-bottom: 4px;
          }
          .icon-up {
            font-size: 24px;
          }
          p {
            color: #1a1a1a;
          }
          .back-top-button:hover,
          .back-top-button:hover p {
            color: #406599;
          }
        `}
      </style>
    </div>
  )
}

function scrollSmoothly(scrollElement: Element, dis: number) {
  let moved = 0
  const initTop = scrollElement.scrollTop
  return function scroll() {
    if (Math.abs(moved) < Math.abs(dis)) {
      /**
       * 200ms内移动dis的距离
       * 200 / 16 得出一共要多少帧
       * dis / (200 / 16)得出每一帧移动的距离
       */
      moved = moved + dis / (200 / 16)
      if (dis > 0) {
        moved = Math.min(moved, dis)
      } else {
        moved = Math.max(moved, dis)
      }
      scrollElement.scrollTop = initTop + moved

      requestAnimationFrame(scroll)
    }
  }
}
