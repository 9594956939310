import React, { useState, useContext, useEffect, useCallback } from 'react'
import fetch from 'isomorphic-unfetch'
import applyHttpsImage from 'lib/apply-https-img'
import getUrl from 'lib/url'
import cookie from 'cookie'
import { GlobalStoreContext } from 'Components/store'
import {
  getLoginHref,
  getRegisterHref,
  getLogoutHref,
} from 'lib/get-login-href'
import { teaConfig } from 'lib/tea-analyze'

const fetchAntiToken = () => {
  return fetch(getUrl('/tt-anti-token'))
    .then(res => res.json())
    .then(res => {
      window.localStorage.setItem('tt-anti-token', res?.data?.token || '')
    })
    .catch(err => {
      console.error(err)
    })
}

export default function User() {
  // 设置全局的城市
  const { state: globalStore, dispatch } = useContext(GlobalStoreContext)
  const { userData } = globalStore
  const [loginHref, setLoginHref] = useState('')
  const [registryHref, setRegistryHref] = useState('')
  const [logoutHref, setLogooutHref] = useState('')

  const fetchUserData = useCallback(() => {
    const cookies = cookie.parse(document.cookie)
    let userData
    try {
      userData = JSON.parse(cookies?.user_data)
    } catch (error) {
      // console.error(error)
    }
    if (userData) {
      dispatch({
        type: 'setUserData',
        payload: userData,
      })
    }
  }, [dispatch])

  useEffect(() => {
    if (!userData) {
      fetchUserData()
      fetchAntiToken()
    }

    setLoginHref(getLoginHref())
    setRegistryHref(getRegisterHref())
    setLogooutHref(getLogoutHref())
  }, [fetchUserData, userData])

  const isLogin = userData && userData.user_id
  const screenName = (userData && userData.screen_name) || ''

  useEffect(() => {
    if (userData?.user_id) {
      sessionStorage.setItem('userData', JSON.stringify(userData))
      teaConfig({
        user_id: userData.user_id,
      })
    }
  }, [userData?.user_id])

  return (
    <div
      className={`user-wrap ${isLogin ? 'login' : ''}`}
      onClick={(e: React.MouseEvent<HTMLElement, MouseEvent>) => {
        e.stopPropagation()
      }}
    >
      {isLogin ? (
        <div className="avatar-wrap">
          <div className="avatar" />
        </div>
      ) : (
        <div>
          <a href={loginHref}>
            <span className="text">登录</span>
          </a>
          <span className="vertical-line" />
          <a href={registryHref}>
            <span className="text">注册</span>
          </a>
        </div>
      )}
      <ul className="dropdown-panel">
        <li className="dropdown-item">
          <div className="user-wrapper">
            <div className="avatar" />
            <div className="username-wrapper">
              <p className="username">{screenName}</p>
            </div>
          </div>
        </li>
        <li className="dropdown-item">
          <a href={logoutHref}>
            <span className="ic-logout" />
            退出登录
          </a>
        </li>
      </ul>
      <style jsx>
        {`
          .user-wrap {
            float: right;
            padding-left: 32px;
            padding-bottom: 22px;
            font-size: 16px;
            position: relative;
          }
          .user-wrap.login:hover .dropdown-panel {
            display: block;
          }
          .dropdown-panel {
            display: none;
            position: absolute;
            top: 52px;
            right: 0;
            z-index: 1000;
            width: 235px;
            min-height: 54px;
            overflow: hidden;
            color: #1a1a1a;
            background-color: #ffffff;
            box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.2);
          }
          .dropdown-item {
            text-align: center;
            min-height: 54px;
            line-height: 54px;
            position: relative;
            user-select: none;
          }
          .dropdown-item .user-wrapper {
            min-height: 54px;
            display: flex;
            flex-direction: row;
            background: #f8f8f8;
            padding: 12px 0 12px 16px;
          }
          .dropdown-item .user-wrapper .username-wrapper {
            flex: 1;
            width: 100%;
            padding: 0 16px 0 12px;
            text-align: left;
            line-height: initial;
          }
          .dropdown-item .user-wrapper .avatar {
            position: initial;
            margin: 0;
          }
          .dropdown-item a {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            padding-left: 20px;
          }
          .ic-logout {
            display: inline-block;
            width: 16px;
            height: 16px;
            background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAYAAABXAvmHAAAAAXNSR0IArs4c6QAABOpJREFUaAXVWj1PHEkQnTG2BBFISHyJgJM3Q7Ylg87ZhXc6OXJmO/BeCpLvD5xD/wNLtsNbBwehUxxehgRIJ0SGJQLECiI4hCBA2qtXdI1qeqt7e3Z3Dnuk2e6uj1evpqune1nyLMtG6Pav3BcY4xQbwy1J1EmwYpu7vuHy8vLDTqfzhORzvu6Wx0d5nm9ubW3tOh78APHBM7C4uDg+Ojr6jsYvb5lor/B/XV1dvd7b2/sXhjID+XdCHpxfEle0TXzwDCwtLT2i/jYEdKG21uj+ikHCNcy1EKv9BnF5TvdN6eT5Y5QTZgCCH+mWa217e5uzI0GMXEwnWIO0XcnQgwbeC3y4dbp7BwO69IKVJx8iCHlIx2BD+rBi7Cts5ixrQMm5azlDEZL7/im2XU/YAEG8qF0oAQMriXy/CcZICqZlk6cmICBWYpD10of8RK79LaLBGCkJaHAJKG1Jt7CwkE9MTPwC5enp6cbBwUGIjPhbLTBDfqV4cJZFbAHFZADqAgP5kZGRz7gnJyc/IqEYSERn4lv2nABt0cXq1n2LZEBmYTeRxPz8PGIIIWkte0vW8wFwCV1eXq6PjY0xAPoOyXK2ZEVglA2RbpGg6YTN6elpdFcODw91Wfg4Wudciwa2QT0SyOlcAQPsvnL5ASC3ZCV7qvns+vp6xZFuOmUoCfFFq7EtslYS7GMtYg0mQSyZH5ht3ZPuJwk/lp+IxSH3F7FpJMiqhZ1lyyZI4vj4eIUGLeWDmfgwNzfnx1QmpW4QX1ulgmmflH5OSWTtdnuVjEtJzM7OvndJgGAvkr30xXE6hZTYxEBLuqOjI5TBKpGGb7Em3HjV6cXHLxmJF231DAiQdvBl/jhmyzqQjMyExtP9nrhioBMQWT9tKDhjDSGJIKcqCYRIhuSloAMmEYzBr9FGo3FnfHwc33YapahqQF8ggiDKrGeXcA7J6B/a8fEtEFeTFvUP1P7q1gQLUz84AUf+z5gTBYypB9JRUj/NzMy8pQT+cEAI5i9qk0CVEhqIZF3OPANnZ2frNAuIYZbQgOVjPbmnqoQy6v9Nb6o3XpLWLHgm2c0Xmv39fUxX7CxkkbBkXQG0gHbinE6n70km9Q91i0pH9gRtntSvs4RKCSryrxSzFvaIfhavYNSZgMTIAuQ/VSVvlTKvgSJSDZ0QeTorrdKBz3/TVGZQ6wzUTR7Z1pbA/0EeCdRSQrSz5jg2E75esJ8GLRt63XaV3NBnoAL5LjJ4olUvngE6C+XuOJFhU3P7Quk1mAKsyDeVvfXkh0IeMTgBfRZyO7Le1BSXUhckSklOTU39TDJNnt/zfb5tUpLsSAk1FDXdF3EKmNhKO/AmJUCxlmcgZlBFd3Jy8oVm4Rl80B9gh7UemCUr3kKWErJSiRjJlGxAmO4Nw642kZRQSgArSfiF5CmYlo2FZ8nYt0oCVjCRBQOIQWJbGadXAj6gP9a8YjptF+qH/ENyxpEE2gr1vupb3RggdDF9CC/VR3NjzvIW2lTIz92vgfJjn1LddK1jbZfREATe0QHk8YcHvogDc8Zb5h4kRLpFDf+EifE3fuGn4N/AUUoou7i4eE3jlB0Yfrd5rZ2fn//uCPDfelBGxfueZuIBjfv+Z49+yssrldDDaaNsdnZ25J89eN2AeCkBz7tITMktmVJ3dS371EULMMu2kP0HV0qUUK+ArEEAAAAASUVORK5CYII=)
              0 0 / 100% 100% no-repeat;
            margin-right: 16px;
          }
          .text:hover {
            color: #e65800;
          }
          .vertical-line {
            display: inline-block;
            width: 1px;
            height: 12px;
            background: #666666;
            margin: 0 8px;
          }
          .avatar-wrap {
            position: relative;
          }
          .avatar {
            width: 24px;
            height: 24px;
            border-radius: 12px;
            border: 1px solid #e6e6e6;
            background: no-repeat
              url(${applyHttpsImage((userData && userData.avatar_url) || '')})
              center / cover;
            position: relative;
            top: 50%;
            left: 0;
            margin-top: 6px;
          }
        `}
      </style>
    </div>
  )
}
