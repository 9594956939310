import * as React from 'react'
import classnames from 'classnames'

import Link from 'next/link'
import Router from 'next/router'

import getLocation from 'lib/get-location'
import getLinkHref from 'lib/get-link-href'
import { sendTeaCommEvent } from 'lib/tea-analyze'
import { GlobalStoreContext } from 'Components/store'

import City from './city'
import Search from './search'
import User from './user'

interface IProps {
  router: {
    asPath: string
    pathname: string
  }
}

const live_white = require('static/live-white.gif')
const live_yellow = require('static/live-yellow.gif')

const routes = [
  {
    href: '/home',
    as: getLinkHref('/'),
    text: '首页',
  },
  {
    href: '/auto/library/x-x-x-x-x-x-x-x-x-x-x',
    as: getLinkHref('/auto/library/x-x-x-x-x-x-x-x-x-x-x'),
    text: '车型库',
  },
  {
    href: '/car_fans_community/home',
    as: getLinkHref('/car_fans_community'),
    text: '车友圈',
  },
  {
    href: '/feed/home',
    as: getLinkHref('/feed'),
    text: '资讯',
  },
  {
    href: '/live/home',
    as: getLinkHref('/live'),
    text: '直播',
    icon: live_white,
  },
  {
    href: '/picture/home',
    as: getLinkHref('/picture'),
    text: '图片',
  },
  {
    href: '/video/home',
    as: getLinkHref('/video'),
    text: '视频',
  },
]

function Header() {
  // 当前激活的tab
  const [active, updateActive] = React.useState<string>()
  const [showCity, setCityPopup] = React.useState(false)
  // 防止ssr与前端渲染的html不一致的问题，在服务端不渲染城市
  const { state, dispatch } = React.useContext(GlobalStoreContext)
  const { cityName } = state
  // 搜索页要隐藏搜索框
  const [hideSearch, setSearch] = React.useState(true)
  const asPath = (Router && Router.router && Router.router.asPath) || ''
  React.useEffect(() => {
    const pathname = asPath.split('?')[0]
    updateActive(pathname)
    setSearch(asPath.includes('/search'))
  }, [asPath])
  React.useEffect(() => {
    if (typeof state.cityName === 'undefined') {
      try {
        const cityName = window.localStorage.getItem('cityName')
        // 优先从本地存储中获取城市
        if (cityName) {
          dispatch({
            type: 'setCityName',
            payload: cityName || '',
          })
        } else {
          // 获取不到时，调用location获取
          getLocation().then(location => {
            dispatch({
              type: 'setCityName',
              payload: location.cityName || '',
            })
          })
        }
      } catch (err) {}
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.cityName])
  return (
    <header className="header">
      <Link href="/home" as="/">
        <a target="_blank" className="fl logo" />
      </Link>
      <div className="inner search-row clearfix">
        {!hideSearch && <Search />}
        <User />
        <a
          className="dcdmp"
          target="_blank"
          rel="noopener noreferrer"
          href="https://mp.dcdapp.com/?link_source=pc"
        >
          进入懂车号
        </a>
        <a
          onClick={() => {
            sendTeaCommEvent('click_top_download_app')
          }}
          target="_blank"
          rel="noopener noreferrer"
          href="https://www.dcdapp.com/download/"
          className="download"
        >
          <span className="code-icon" />
          下载APP
          <div className="vrcode-container">
            <h3>扫码下载懂车帝APP</h3>
            <img
              src={require('static/dcd-code@3x.png')}
              alt=""
              className="vrcode"
            />
          </div>
        </a>
        <div
          className="city"
          onMouseEnter={event => {
            event.stopPropagation()
            event.nativeEvent.stopImmediatePropagation()
            sendTeaCommEvent('mouseenter_top_location', {
              location_city: cityName,
            })
            setCityPopup(true)
          }}
          onMouseLeave={event => {
            event.stopPropagation()
            event.nativeEvent.stopImmediatePropagation()
            setCityPopup(false)
          }}
        >
          <span className="city-icon" />
          {cityName}
          {showCity && (
            <City
              onChange={name => {
                sendTeaCommEvent('switch_top_location', {
                  location_city: name,
                })
                setCityPopup(false)
              }}
            />
          )}
          <span className="icon-arrow-ic-r arrow-down" />
        </div>
      </div>
      <div className="inner nav-row clearfix">
        {routes.map(item => (
          <Link key={item.text} href={item.href} as={item.as}>
            <a
              //target="_blank"
              className={classnames('fl nav', {
                active: active === item.as,
                hasicon: !!item.icon,
              })}
              onClick={() => {
                sendTeaCommEvent('click_top_guide', {
                  obj_text: item.text,
                })
              }}
            >
              {item.icon && <img src={item.icon} className="nav-icon" />}
              {item.text}
            </a>
          </Link>
        ))}
        {/* <Link href="/download">
          <a className="download">客户端</a>
        </Link> */}
      </div>
      <style jsx>
        {`
          .header {
            width: 1196px;
            height: 100px;
            margin: 0 auto;
            margin-top: 20px;
            color: #1f2129;
            font-size: 18px;
            position: relative;
          }
          .inner {
            width: 1196px;
            padding-left: 100px;
          }
          .logo {
            width: 246px;
            height: 100px;
            background: url(${require('static/newLogo.png')}) no-repeat 0 0 /
              cover;
          }
          .search-row {
            height: 52px;
            line-height: 36px;
            font-size: 14px;
          }
          .nav-row {
            background: #1f2129;
            height: 48px;
            line-height: 48px;
            border-radius: 4px;
            background-image: linear-gradient(
              to bottom,
              rgba(0, 0, 0, 0.6),
              rgba(0, 0, 0, 0)
            );
          }
          .nav {
            display: inline-flex;
            justify-content: center;
            align-items: center;
            margin-left: 56px;
            color: #fff;
            transition: all 0.1s ease-in-out;
            position: relative;
          }
          .hasicon {
            margin-left: 80px;
          }
          .nav:first-child {
            margin-left: 40px;
          }
          .nav:hover {
            color: #ffcd32;
          }
          .nav:hover .nav-icon{
            content: url('${live_yellow}');
          }
          .nav:after {
            content: '';
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
            height: 4px;
            background: transparent;
          }
          .active {
            color: #ffcd32;
          }
          .active .nav-icon{
            content: url('${live_yellow}');
          }
          .nav.active:after {
            background: #ffcd32;
          }
          .nav-icon{
            position: absolute;
            left: -24px;
            width: 24px;
            height: 24px;
            display: inline-block;
            object-fit: cover;
            object-position: center;
          }
          .city {
            position: relative;
            float: right;
            margin-right: 32px;
            font-size: 16px;
            cursor: pointer;
            user-select: none;
            height: 52px;
          }
          .city:hover {
            color: #e65800;
          }
          .city:hover .city-icon {
            background-image: url(${require('static/location-ic-red.png')});
          }
          .city::before:hover {
            color: #e65800;
          }
          .city:hover .arrow-down {
            color: #e65800;
          }
          .arrow-down {
            display: inline-block;
            font-size: 16px;
            color: #1f2129;
            margin-left: 8px;
            margin-top: -3px;
            transform: rotate(90deg);
            vertical-align: middle;
          }
          .city-icon {
            background-image: url(${require('static/location-ic@3x.png')});
            background-position: center;
            background-repeat: no-repeat;
            background-size: 100%;
            display: inline-block;
            width: 16px;
            height: 16px;
            vertical-align: -3px;
            margin-right: 4px;
          }
          .header .city :global(> section) {
            left: 0;
          }
          .dcdmp {
            float: right;
            font-size: 16px;
            margin-left: 32px;
          }
          .dcdmp:hover {
            color: #e65800;
          }
          .download {
            position: relative;
            float: right;
            font-size: 16px;
            height: 52px;
          }
          .download:hover .code-icon {
            background-image: url(${require('static/code-ic-red.png')});
          }
          .download:hover {
            color: #e65800;
          }
          .download:hover .vrcode-container {
            display: block;
          }

          .code-icon {
            display: inline-block;
            width: 16px;
            height: 16px;
            background-image: url(${require('static/code-ic@3x.png')});
            background-position: center;
            background-repeat: no-repeat;
            background-size: 100%;
            vertical-align: -2px;
            margin-right: 4px;
          }
          .vrcode-container {
            display: none;
            position: absolute;
            top: 52px;
            left: 0px;
            width: 200px;
            height: 236px;
            z-index: 2000;
            box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.2);
            text-align: center;
            background-color: #ffffff;
          }
          .vrcode {
            width: 160px;
            height: 160px;
            margin: 20px 0;
          }
          h3 {
            font-size: 14px;
            color: #1f2129;
            font-weight: normal;
            margin-top: 16px;
            line-height: 20px;
          }
          .right {
            margin-right: 0;
          }
        `}
      </style>
    </header>
  )
}

export default Header
