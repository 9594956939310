import get from 'lodash/get'
import fetch from 'isomorphic-unfetch'

interface Coordinates {
  latitude: number
  longitude: number
}
interface Result extends Coordinates {
  status: number
  cityName: string
}

export interface ILocation {
  latitude: number
  longitude: number
  gpsLatitude: number
  gpsLongitude: number
  ipLatitude: number
  ipLongitude: number
  cityName: string
  gpsCityName: string
  ipCityName: string
}

let mapKey = ''
/* eslint-disable prefer-destructuring */
export default async function getLocation(
  key = '88db9775ba89ac7e7afafbecd43b96e7'
): Promise<ILocation> {
  try {
    mapKey = key
    const [gpsLocation, ipLocation] = await Promise.all([
      getGPSCityName(),
      getIPCityName(),
    ])
    let cityName = ''
    let gpsCityName = ''

    if (gpsLocation.status === 0 && gpsLocation.cityName) {
      // gps地址获取成功
      console.log('[utils/location] gps success:::', gpsLocation)
      cityName = gpsLocation.cityName
      gpsCityName = cityName
    } else if (ipLocation.status === 0 && ipLocation.cityName) {
      // ip地址获取成功
      console.log('[utils/location] ip success:::', ipLocation)
      cityName = ipLocation.cityName
    }
    const dataBaseResult = await getDatabaseCity(cityName)
    return {
      latitude: gpsLocation.latitude || ipLocation.latitude,
      longitude: gpsLocation.longitude || ipLocation.longitude,
      gpsLatitude: gpsLocation.latitude,
      gpsLongitude: gpsLocation.longitude,
      ipLatitude: ipLocation.latitude,
      ipLongitude: ipLocation.longitude,
      cityName: dataBaseResult.cityName,
      gpsCityName,
      ipCityName: ipLocation.cityName,
    }
  } catch (error) {
    console.warn(error)
    return {
      latitude: 0,
      longitude: 0,
      gpsLatitude: 0,
      gpsLongitude: 0,
      ipLatitude: 0,
      ipLongitude: 0,
      cityName: '',
      gpsCityName: '',
      ipCityName: '',
    }
  }
}

function getGPSLocation(): Promise<
  {
    status: number
  } & Coordinates
  > {
  const defaultResult = {
    status: -1,
    latitude: 0,
    longitude: 0,
  }

  const timeout: Promise<{
    status: number
  } & Coordinates> = new Promise(resolve => {
    setTimeout(() => {
      resolve(defaultResult)
    }, 3000)
  })

  const getCurrentPosition: Promise<{
    status: number
  } & Coordinates> = new Promise(resolve => {
    try {
      const positionOptions = {
        timeout: 3000,
        maximumAge: 60 * 60 * 1000,
      }

      window.navigator.geolocation.getCurrentPosition(
        res => {
          if (res && res.coords) {
            const { latitude = 0, longitude = 0 } = res.coords
            resolve({
              status: 0,
              latitude,
              longitude,
            })
          } else {
            resolve(defaultResult)
          }
        },
        () => {
          resolve(defaultResult)
        },
        positionOptions
      )
    } catch (err) {
      resolve(defaultResult)
    }
  })

  return Promise.race([timeout, getCurrentPosition])
}

function getIPLocation(): Promise<Result> {
  return fetch('/motor/car_service/open_api/get_ip_city/')
    .then(res => res.json())
    .then(json => {
      return {
        status: 0,
        cityName: get(json, 'data.city_name', ''),
        latitude: get(json, 'data.Latitude', 0),
        longitude: get(json, 'data.Longitude', 0),
      }
    })
    .catch(() => {
      return {
        status: -1,
        cityName: '',
        latitude: 0,
        longitude: 0,
      }
    })
}

function getCityByPos(coordinate: Coordinates): Promise<Result> {
  return fetch(
    `https://restapi.amap.com/v3/geocode/regeo?key=${mapKey}&location=${coordinate.longitude},${coordinate.latitude}`
  )
    .then(res => res.json())
    .then(json => {
      try {
        // province 可能返回字符串或数组
        const { city = [], province = [] } = get(
          json,
          'regeocode.addressComponent',
          {}
        )
        const cityResult = typeof city === 'string' ? city : city[0]
        const provinceResult =
          typeof province === 'string' ? province : province[0]

        let result = cityResult || provinceResult || ''

        result = result.replace('市', '')
        const currentCity = result || ''
        console.log('[utils/location] getCityByPos response:::', json.data)
        return Promise.resolve({
          status: 0,
          cityName: currentCity,
          ...coordinate,
        })
      } catch (error) {
        return {
          status: -1,
          cityName: '',
          ...coordinate,
        }
      }
    })
}

function getDatabaseCity(
  cityName: string
): Promise<{
  status: number
  cityName: string
  match: boolean
}> {
  return fetch(
    `/motor/car_service/open_api/get_city_name/?city_name=${encodeURIComponent(
      cityName
    )}`
  )
    .then(res => res.json())
    .then(json => {
      try {
        const dbCityName = get(json, 'data.city_name', '')
        const match = get(json, 'data.match', true)
        return Promise.resolve({
          status: 0,
          cityName: dbCityName,
          match,
        })
      } catch (error) {
        return {
          status: -1,
          cityName: '',
          match: false,
        }
      }
    })
    .catch(e => {
      return {
        status: -1,
        cityName: '',
        match: false,
      }
    })
}

function getGPSCityName() {
  return getGPSLocation().then(getCityByPos)
}

function getIPCityName(): Promise<Result> {
  return fetch('/motor/car_service/open_api/get_ip_city/')
    .then(res => res.json())
    .then(json => ({
      status: 0,
      cityName: get(json, 'data.city_name', ''),
      latitude: get(json, 'data.Latitude', 0),
      longitude: get(json, 'data.Longitude', 0),
    }))
    .catch(() => {
      return {
        status: -1,
        cityName: '',
        latitude: 0,
        longitude: 0,
      }
    })
}
